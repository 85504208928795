import { DrawerItemType } from "../enums/drawer-item-types.enum";
import {
	deliveryIcon,
	fileIcon,
	invoiceIcon,
	linkIcon,
	lockIcon,
	tooltipIcon,
} from "./icon.constants";

const operationRoutes = [
	{
		text: "Operations",
		svgIcon: deliveryIcon,
		id: 0,
		linksVisible: false,
		itemType: DrawerItemType.Category,
	},
	{
		text: "Jobs",
		id: 1,
		itemType: DrawerItemType.Link,
		parentId: 0,
		route: "/jobs2",
	},
	{
		text: "Legs",
		id: 15,
		parentId: 0,
		itemType: DrawerItemType.Link,
		route: "/legs2",
	},
	{
		text: "Loads",
		id: 18,
		itemType: DrawerItemType.Link,
		parentId: 0,
		route: "/loads2",
	},
	{
		text: "Search",
		id: 192312321,
		itemType: DrawerItemType.Link,
		parentId: 0,
		route: "/search",
	},
];

const oldPages = [
	{
		text: "Old Pages",
		svgIcon: tooltipIcon,
		id: 1313,
		linksVisible: false,
		itemType: DrawerItemType.Category,
	},
	{
		text: "Jobs",
		id: 1513,
		parentId: 1313,
		itemType: DrawerItemType.Link,
		route: "/jobs",
	},
	{
		text: "Legs",
		id: 1515,
		parentId: 1313,
		itemType: DrawerItemType.Link,
		route: "/legs",
	},
	{
		text: "Loads",
		id: 1514,
		parentId: 1313,
		itemType: DrawerItemType.Link,
		route: "/loads",
	},
	{
		text: "Subcontractors",
		id: 1516,
		parentId: 1313,
		itemType: DrawerItemType.Link,
		route: "/subcontractors",
	},
	{
		text: "Drivers",
		id: 1527,
		parentId: 1313,
		itemType: DrawerItemType.Link,
		route: "/drivers",
	},
	{
		text: "Locations",
		id: 1517,
		parentId: 1313,
		itemType: DrawerItemType.Link,
		route: "/locations",
	},
	{
		text: "Truck Types",
		id: 1518,
		parentId: 1313,
		itemType: DrawerItemType.Link,
		route: "/truck-types",
	},
	{
		text: "Trailer Types",
		id: 1111,
		parentId: 1313,
		itemType: DrawerItemType.Link,
		route: "/trailer-types",
	},
	{
		text: "Constraints",
		id: 1519,
		parentId: 1313,
		itemType: DrawerItemType.Link,
		route: "/constraints",
	},
	{
		text: "Load Types",
		id: 1520,
		parentId: 1313,
		itemType: DrawerItemType.Link,
		route: "/load-types",
	},
	{
		text: "Users",
		id: 1521,
		parentId: 1313,
		itemType: DrawerItemType.Link,
		route: "/users",
	},
	{
		text: "Exchange Rates",
		id: 1522,
		parentId: 1313,
		itemType: DrawerItemType.Link,
		route: "/exchange-rates",
	},
	{
		text: "Customers",
		id: 1526,
		parentId: 1313,
		itemType: DrawerItemType.Link,
		route: "/customers",
	},
	{
		text: "Invoicing Check",
		id: 1523,
		parentId: 1313,
		itemType: DrawerItemType.Link,
		route: "/invoicing-check",
	},
	{
		text: "Invoicing",
		id: 1524,
		parentId: 1313,
		itemType: DrawerItemType.Link,
		route: "/invoices",
	},
	{
		text: "Supplier Invoices",
		id: 1525,
		parentId: 1313,
		itemType: DrawerItemType.Link,
		route: "/supplier-invoices",
	},
	{
		text: "Trucks",
		id: 1528,
		parentId: 1313,
		itemType: DrawerItemType.Link,
		route: "/trucks",
	},
	{
		text: "Trailers",
		id: 1529,
		parentId: 1313,
		itemType: DrawerItemType.Link,
		route: "/trailers",
	},
];

const accountingRoutes = [
	{
		text: "Accountant",
		svgIcon: invoiceIcon,
		id: 12,
		linksVisible: false,
		itemType: DrawerItemType.Category,
	},
	{
		text: "Invoicing Check",
		id: 21,
		parentId: 12,
		itemType: DrawerItemType.Link,
		route: "/invoicingcheck2",
	},
	{
		text: "Invoicing",
		id: 22,
		parentId: 12,
		itemType: DrawerItemType.Link,
		route: "/invoicing2",
	},
	{
		text: "Supplier Invoices",
		id: 23,
		parentId: 12,
		itemType: DrawerItemType.Link,
		route: "/supplierinvoices2",
	},
];

export const adminRoutes = [
	/** Operations section */
	...operationRoutes,
	/** Assets section */
	{
		text: "Assets",
		svgIcon: fileIcon,
		id: 2,
		linksVisible: false,
		itemType: DrawerItemType.Category,
	},
	{
		text: "Drivers",
		id: 4,
		parentId: 2,
		itemType: DrawerItemType.Link,
		route: "/drivers2",
	},
	{
		text: "Trucks",
		id: 5,
		parentId: 2,
		itemType: DrawerItemType.Link,
		route: "/trucks2",
	},
	{
		text: "Trailers",
		id: 6,
		parentId: 2,
		itemType: DrawerItemType.Link,
		route: "/trailers2",
	},

	{
		text: "Subcontractors",
		id: 7,
		parentId: 2,
		itemType: DrawerItemType.Link,
		route: "/subcontractors2",
	},
	{
		text: "Customers",
		id: 8,
		parentId: 2,
		itemType: DrawerItemType.Link,
		route: "/customers2",
	},
	{
		text: "Locations",
		id: 19,
		parentId: 2,
		itemType: DrawerItemType.Link,
		route: "/locations2",
	},
	/** Constraints section */
	{
		text: "Constraints",
		svgIcon: linkIcon,
		id: 3,
		linksVisible: false,
		itemType: DrawerItemType.Category,
	},
	{
		text: "Truck Types",
		id: 9,
		parentId: 3,
		itemType: DrawerItemType.Link,
		route: "/trucktypes2",
	},
	{
		text: "Trailer Types",
		id: 10,
		parentId: 3,
		itemType: DrawerItemType.Link,
		route: "/trailertypes2",
	},
	{
		text: "Constraints",
		id: 11,
		parentId: 3,
		itemType: DrawerItemType.Link,
		route: "/constraints2",
	},
	/** Accountant section */
	...accountingRoutes,
	/** Management section */
	{
		text: "Management",
		svgIcon: lockIcon,
		id: 20,
		linksVisible: false,
		itemType: DrawerItemType.Category,
	},
	{
		text: "Load Types",
		id: 14,
		parentId: 20,
		itemType: DrawerItemType.Link,
		route: "/loadtypes2",
	},
	{
		text: "Users",
		id: 16,
		parentId: 20,
		itemType: DrawerItemType.Link,
		route: "/users2",
	},
	{
		text: "Exchange Rates",
		id: 17,
		parentId: 20,
		itemType: DrawerItemType.Link,
		route: "/exchangerates2",
	},
	...oldPages,
];

export const operatorRoutes = [
	/** Operations section */
	...operationRoutes,
	/** Assets section */
	{
		text: "Assets",
		svgIcon: fileIcon,
		id: 2,
		linksVisible: false,
		itemType: DrawerItemType.Category,
	},
	{
		text: "Drivers",
		id: 4,
		parentId: 2,
		itemType: DrawerItemType.Link,
		route: "/drivers2",
	},
	{
		text: "Trucks",
		id: 5,
		parentId: 2,
		itemType: DrawerItemType.Link,
		route: "/trucks2",
	},
	{
		text: "Trailers",
		id: 6,
		parentId: 2,
		itemType: DrawerItemType.Link,
		route: "/trailers2",
	},
	{
		text: "Subcontractors",
		id: 7,
		parentId: 2,
		itemType: DrawerItemType.Link,
		route: "/subcontractors2",
	},
	{
		text: "Locations",
		id: 19,
		parentId: 2,
		itemType: DrawerItemType.Link,
		route: "/locations2",
	},
	/** Constraints section */
	{
		text: "Constraints",
		svgIcon: linkIcon,
		id: 3,
		linksVisible: false,
		itemType: DrawerItemType.Category,
	},
	{
		text: "Truck Types",
		id: 9,
		parentId: 3,
		itemType: DrawerItemType.Link,
		route: "/trucktypes2",
	},
	{
		text: "Trailer Types",
		id: 10,
		parentId: 3,
		itemType: DrawerItemType.Link,
		route: "/trailertypes2",
	},
	{
		text: "Constraints",
		id: 11,
		parentId: 3,
		itemType: DrawerItemType.Link,
		route: "/constraints2",
	},
	...oldPages,
];

export const accountantRoutes = [
	/** Operations section */
	...operationRoutes,
	/** Assets section */
	{
		text: "Assets",
		svgIcon: fileIcon,
		id: 2,
		linksVisible: false,
		itemType: DrawerItemType.Category,
	},
	{
		text: "Customers",
		id: 8,
		parentId: 2,
		itemType: DrawerItemType.Link,
		route: "/customers2",
	},
	/** Accountant section */
	...accountingRoutes,
	/** Management section */
	{
		text: "Management",
		svgIcon: lockIcon,
		id: 20,
		linksVisible: false,
		itemType: DrawerItemType.Category,
	},
	{
		text: "Exchange Rates",
		id: 17,
		parentId: 20,
		itemType: DrawerItemType.Link,
		route: "/exchangerates2",
	},
	...oldPages,
];

export const superAdminRoutes = [
	...adminRoutes,
	{
		text: "Super Admin",
		svgIcon: fileIcon,
		id: 13,
		linksVisible: false,
		itemType: DrawerItemType.Category,
	},
	{
		text: "Organisations",
		id: 24,
		parentId: 13,
		itemType: DrawerItemType.Link,
		route: "/organisations",
	},
	{
		text: "Business Units",
		id: 25,
		parentId: 13,
		itemType: DrawerItemType.Link,
		route: "/business-units",
	},
	{
		text: "Business Unit Settings",
		id: 28,
		parentId: 13,
		itemType: DrawerItemType.Link,
		route: "/business-unit-settings",
	},
	{
		text: "Business Unit Banks",
		id: 29,
		parentId: 13,
		itemType: DrawerItemType.Link,
		route: "/business-unit-banks",
	},
	{
		text: "Users",
		id: 26,
		parentId: 13,
		itemType: DrawerItemType.Link,
		route: "/users2",
	},
	{
		text: "Job Types",
		id: 27,
		parentId: 13,
		itemType: DrawerItemType.Link,
		route: "/jobtypes2",
	},
	{
		text: "Move Orders",
		id: 32323,
		parentId: 13,
		itemType: DrawerItemType.Link,
		route: "/planning",
	},
	/** Server section */
	{
		text: "Server",
		svgIcon: lockIcon,
		id: 30,
		linksVisible: false,
		itemType: DrawerItemType.Category,
	},
	{
		text: "Release Notes",
		id: 31,
		parentId: 30,
		itemType: DrawerItemType.Link,
		route: "/release-notes",
	},
	{
		text: "Server Status",
		id: 32,
		parentId: 30,
		itemType: DrawerItemType.Link,
		route: "/server-status",
	},
];
